import qf from './qfunctions';

const init_gdpr = () => {
    let labels = document.querySelectorAll(".gdpr label");
    labels.forEach((label) => {
        let show_link = label.querySelector('.show_link');
        let show_text = label.querySelector('.text');
        let close_link = label.querySelector('.text a');
        show_link.addEventListener('click', (event) => {
            event.preventDefault();
            show_text.style.display = "block";
        });
        close_link.addEventListener('click', (event) => {
            event.preventDefault();
            show_text.style.display = null;
        });
    })
}

const init_djangoforms = () => {
    let formcontainers = document.querySelectorAll(".djangoform");
    formcontainers.forEach((formcontainer) => {
        let form = formcontainer.querySelector('form');
        form.addEventListener("submit", (event) => {
            event.preventDefault();
            form.querySelectorAll('.formfield').forEach((elem) => {
                qf.removeClass(elem, 'error');
            });
            let b_submit = form.querySelector('input[type=submit]');
            if (form.getAttribute('sending')) return;
            form.setAttribute('sending', true);
            b_submit.setAttribute('disabled', true);
            let formAction = form.getAttribute("action");
            let formData = new FormData(form);
            qf.doPost(formAction, formData).then((data)=>{
                if (data.status == 'ok') {
                    window.location = data.redirect;
                } else {
                    for (let error of data.errors) {
                        let field = form.querySelector('[name="' + error + '"]');
                        let container = qf.getClosest(field, 'formfield');
                        qf.addClass(container, 'error');
                    }
                    b_submit.removeAttribute('disabled');
                    form.removeAttribute('sending', false);
                }
            }, () => {
                alert('error. try again later.')
            })
        });
    });
}

const init = () => {
    init_gdpr();
    init_djangoforms();
}

export default { init };

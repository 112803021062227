const hasClass = (elem, className) => {
    return ('classList' in elem && elem.classList && elem.classList.contains(className))
}
const addClass = (elem, className) => {
    if (!hasClass(elem, className)) elem.classList.add(className);
    return elem;
}
const removeClass = (elem, className) => {
    if (hasClass(elem, className)) elem.classList.remove(className);
    return elem;
}

const getClosest = (elem, className) => {
    while (elem) {
        if (hasClass(elem, className)) return elem;
        elem = elem.parentNode;
    }
    return null;
}

const doPost = (url, data) => {
    let promise = new Promise((resolve, reject) => {
        let request = new XMLHttpRequest();
        request.open("POST", url);
        request.onreadystatechange = (e) => {
            if (request.readyState != 4) return;
            if (request.status != 200) reject();
            try {
                resolve(JSON.parse(request.responseText));
            } catch {
                reject();
            }
        }
        request.send(data);
    });
    return promise;
}

export default {
    hasClass,
    addClass,
    removeClass,
    getClosest,
    doPost,
};
